<template>
  <section class="section position-relative">
    <div class="mt-4 flex-wrap justify-content-between">
      <UsersTable />
    </div>
    <!-- <div class="mt-4 d-flex">
      <div class="flex-wrap justify-content-between col-md-8 pl-0 py-0">
        <UsersTable />
      </div>
      <div class="flex-wrap justify-content-between col-md-4 px-0 py-0">
        <RolesTable />
      </div>
    </div> -->

    <div class="mt-4 flex-wrap justify-content-between">
      <!-- <div class="flex-wrap justify-content-between col-md-8 pl-0 py-0">
        <UsersTable />
      </div> -->
      <div class="mt-4 flex-wrap justify-content-between">
        <RolesTable />
      </div>
    </div>
  </section>
</template>
<script>
import UsersTable from "./components/UsersTable.vue";
import RolesTable from "./components/RolesTable.vue";

export default {
  components: {
    UsersTable,
    RolesTable,
  },
};
</script>
