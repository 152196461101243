<template>
  <CustomCard
    title="Activité des utilisateurs"
    @change="filterUsers"
    @click="() => {}"
    :canAdd="false"
  >
    <template v-slot:table>
      <b-table
        :per-page="perPage"
        :current-page="currentPage"
        :fields="fields"
        :items="items"
        responsive
        show-empty
        striped
        bordered
        id="targeted-table"
        :busy="loading"
        class="mb-auto col-12 p-0"
      >
        <!-- FOR LOADING ANIMATIONS -->
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Chargement...</strong>
          </div>
        </template>
        <!-- SHOW IF TABLE IS EMPTY -->
        <template #empty>
          <div class="text-center bg-white p-0 text-gray">Table vide</div>
        </template>
        <template v-slot:cell(name)="data">
          <div class="d-flex">
            <TableResponsable :responsable="data.item" />
            <span class="my-auto ml-1 mr-2">
              {{ data.item.firstname + " " + data.item.lastname }}
            </span>
            <span class="my-auto">
              <i
                v-if="data.item.isVerified"
                class="mdi mdi-check-circle"
                style="color: #4779ec"
              ></i>
              <i v-else class="mdi mdi-check-circle" style="color: #ababab"></i
            ></span>
          </div>
        </template>
        <template v-slot:cell(avatarUrl)="data">
          <TableResponsable :responsable="data.item" />
        </template>
        <template v-slot:cell(type)="data">
          <span> {{ data.value || "-" }} </span>
        </template>
        <template v-slot:cell(roles)="data">
          <span class="text-capitalize">{{
            data.value && data.value.length
              ? data.value[0].toLowerCase().slice(5)
              : "-"
          }}</span>
        </template>
        <template v-slot:cell(isActif)="data">
          <div v-if="!data.item.hasOwnProperty('editMode')">
            <div
              v-if="data.value"
              class="rounded-pill px-2 py-1 text-center"
              style="background-color: #d4f4e8; color: #29cb97; width: 85px"
            >
              Activé
            </div>
            <div
              v-else
              class="rounded-pill px-2 py-1 text-center"
              style="background-color: #ffdae5; color: #ff2366; width: 85px"
            >
              Désactivé
            </div>
          </div>
          <div class="row col-12 p-0 m-0" v-else>
            <div
              id="add-page"
              class="font-weight-normal p-0 form-control"
              style="padding: 0 !important; height: fit-content"
            >
              <!-- :class="{
              'is-invalid':
                $v.contributers.$each[data.index].status.$error &&
                $v.contributers.$each[data.index].status.$dirty,
            }" -->
              <v-select
                class="col-12 p-0 bg-transparent"
                :options="['Activé', 'Désactivé']"
                selected
                style="min-width: max-content"
                v-model="isActif"
                appendToBody
                placeholder="Statut"
              >
                <template #no-options> Liste vide </template>
              </v-select>
            </div>
          </div>
        </template>
        <template #cell(actions)="data">
          <table-actions
            class="justify-content-end"
            :actions="['edit']"
            @editItem="editItem(data.item)"
            @cancelItem="cancelItem(data.item)"
            @confirmItem="confirmItem(data.item)"
            :editMode="data.item.hasOwnProperty('editMode')"
          ></table-actions>
        </template>
      </b-table>
    </template>
    <template v-slot:pagination>
      <div class="ml-auto mt-auto">
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="table-list"
          align="right"
          class="p-0 mt-3 mb-0"
        >
        </b-pagination>
      </div>
    </template>
  </CustomCard>
</template>

<script>
import CustomCard from "../../components/CustomCard.vue";
import { mapGetters } from "vuex";
import TableResponsable from "@/components/custom/TableResponsable.vue";
import TableActions from "@/components/custom/TableActions";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    CustomCard,
    TableResponsable,
    TableActions,
  },
  data() {
    return {
      fields: [
        { key: "name", label: "Nom", sortable: true },
        { key: "roles", label: "Rôle", sortable: true },
        { key: "email", label: "Email", sortable: true },
        { key: "type", label: "Type", sortable: true },
        { key: "isActif", label: "Statut", sortable: true },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "100px !important", minWidth: "100px !important" },
          sortable: false,
        },
      ],
      perPage: 5,
      currentPage: 1,
      loading: true,
      items: [],
      isActif: "",
    };
  },
  validations: {
    isActif: {
      required,
    },
  },
  methods: {
    editItem(item) {
      this.$store.dispatch("users/fetchAllUsers").then(() => {
        this.items = this.items.map((user) =>
          item.id === user.id ? { ...user, editMode: true } : user
        );
        this.isActif = item.isActif ? "Activé" : "Désactivé";
      });
    },
    cancelItem() {
      if (this.isActif === "") this.$store.dispatch("users/fetchAllUsers");
      else this.isActif = "";
    },
    confirmItem(item) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store
          .dispatch("users/updateUser", {
            id: item.id,
            isActif: this.isActif === "Activé",
          })
          .then(() => {
            this.$store.dispatch("users/fetchAllUsers").then(() => {
              this.isActif = "";
            });
          });
      }
    },
    filterUsers(value) {
      this.items = this.USERS.filter(
        (user) =>
          user.firstname.toLowerCase().includes(value.toLowerCase()) ||
          user.lastname.toLowerCase().includes(value.toLowerCase()) ||
          user.email.toLowerCase().includes(value.toLowerCase())
      );
    },
  },
  computed: {
    ...mapGetters("users", ["USERS"]),
    rows() {
      return this.items.length;
    },
  },
  created() {
    this.$store.dispatch("users/fetchAllUsers").then(() => {
      this.loading = false;
    });
  },
  watch: {
    USERS() {
      this.items = this.USERS;
    },
  },
};
</script>
